import axios from 'axios';
import { Buffer } from 'buffer';

import { API_BASE_URL, DOMAIN_HOST } from '../config/appConfig';
import {
  getSessionSettings,
  getSessionTime,
  removeAuthInfo,
  setSessionTime,
} from '../Context/AuthSessionStorage';
import { handleSessionTimings } from '../ReusableFn/reusableFn';
import { decryptData, encryptionKey } from '../helpers/encrypt';

// Set your credentials
const username = 'timeSheet';
const password = '#12345#';

// Create Base64 encoded credentials
const credentials = Buffer.from(`${username}:${password}`).toString('base64');

// Create Axios instance
const axiosClient = axios.create({
  baseURL: API_BASE_URL,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Basic ' + credentials,
  },
});
let loadingCount = 0;
axiosClient.interceptors.request.use(
  async (config) => {
    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    }

    await handleSessionTimings(config);

    loadingCount += 1;
    if (loadingCount === 1) {
      document.getElementById('tmoverlay').className = 'tmoverlay d-block';
    }

    return config;
  },
  (error) => {
    loadingCount -= 1;

    if (loadingCount === 0) {
      document.getElementById('tmoverlay').className = 'd-none';
    }
    return Promise.reject(error);
  },
);

axiosClient.interceptors.response.use(
  (response) => {
    loadingCount -= 1;

    if (loadingCount === 0) {
      document.getElementById('tmoverlay').className = 'd-none';
    }

    response.data = decryptData(response.data, encryptionKey);

    return response;
  },
  (error) => {
    loadingCount -= 1;

    if (loadingCount === 0) {
      document.getElementById('tmoverlay').className = 'd-none';
    }
    return Promise.reject(error);
  },
);

export default axiosClient;

import { useEffect, useRef, useState } from 'react';
import { Row, Col } from 'reactstrap';
import style from '../../Styles/UserManagement.css';
import UserManagementTable from './UserManagementTable';
import { useNavigate } from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import TmButton from '../ReusableComponents/TmButton';
import { Button, Checkbox, Dropdown, Input, Menu, Select } from 'antd';
import UserApi from '../../Api/UserApi';
import {
  toastError,
  toastInfo,
  toastSuccess,
  toastWarn,
} from '../Toast/ToastComponent';
import {
  PaginationRange,
  EMPLOYEE_STATUS_FILTER,
  ACTION_TYPE,
  ACTION_SUBTYPE,
} from '../../constant/constant';
import TmFilter from '../ReusableComponents/TmFilter';
import TmPagination from '../ReusableComponents/Pagination';
import Loader from '../ReusableComponents/Loader';
import Department from '../../Api/Department.js';
import Designation from '../../Api/Designation.js';
import { getUser } from '../../store/reducers/actionPermission.js';
import { useSelector } from 'react-redux';
import { DeletePopup } from '../ReusableComponents/DeletePopup.js';
import { IoIosMail } from 'react-icons/io';
import AntdModalComp from '../ReusableComponents/AntdModalComp.js';
import LoginApi from '../../Api/LoginApi.js';
import { useAuth } from '../../Context/AuthContext.js';
import UserRole from '../../Api/UserRole.js';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { SmileOutlined } from '@ant-design/icons';
import { FaAngleDown } from 'react-icons/fa';

const { Search } = Input;

let ActionType = {
  type: '',
  subType: '',
};

const columns = [
  ,
  { field: 'checkbox', headerName: 'CHECKBOX', className: 'col-auto' },
  { field: 'serialNo', headerName: 'S.NO', className: 'col-1' },
  { field: 'employee_name', headerName: 'USER', className: 'col-2 ps-3' },
  { field: 'employee_Code', headerName: 'EMP ID', className: 'col-1' },
  { field: 'email', headerName: 'EMAIL', className: 'col-2' },
  { field: 'userRole_name', headerName: 'USER ROLE', className: 'col-2' },
  {
    field: 'employee_department',
    headerName: 'DEPARTMENT',
    className: 'col-2',
  },
  { field: 'date_of_joining', headerName: 'DESIGNATION', className: 'col-2' },
  {
    field: 'designation_name',
    headerName: '1ST APPROVERS',
    className: 'col-2',
  },
  { field: 'department_name', headerName: '2ND APPROVERS', className: 'col-2' },
  { field: 'status', headerName: 'STATUS', className: 'col-2' },
  { field: 'action', headerName: 'ACTION', className: 'col-1' },
];

let filterValue = {
  status: 'WORKING',
  departmentID: '',
  designationID: '',
  search: '',
};
const handleCheckboxChange = (event, row) => {
  // Handle checkbox change event here
};
function UserManagement() {
  const debounceTimeoutRef = useRef(null);
  const [enable, setEnable] = useState(false);
  const [modal, setModal] = useState(false);
  const data = useSelector(getUser);

  const { authState, login } = useAuth();
  const [buttonPermission, setButtonPermissions] = useState({
    add: undefined,
    edit: undefined,
    delete: undefined,
    view: undefined,
  });

  const [checkId, setCheckId] = useState([]);

  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [filterList, setFilterList] = useState({
    department: [],
    designation: [],
    userRole: [],
  });

  const filterArray = [
    {
      options: filterList.userRole,
      optionFilterProp: 'label',
      placeholder: 'User Role',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: filterValue?.userRoleID ? filterValue?.userRoleID : null,
      controlName: 'userRoleID',
      allowClear: true,
      input: 'select',
    },
    {
      options: filterList.department,
      optionFilterProp: 'label',
      placeholder: 'Department',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: filterValue?.departmentID
        ? filterValue?.departmentID
        : null,
      controlName: 'departmentID',
      allowClear: true,
      input: 'select',
    },
    {
      options: filterList.designation,
      optionFilterProp: 'label',
      placeholder: 'Designation',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: filterValue?.designationID
        ? filterValue?.designationID
        : null,
      controlName: 'designationID',
      allowClear: true,
      input: 'select',
    },
    {
      options: EMPLOYEE_STATUS_FILTER,
      optionFilterProp: 'label',
      placeholder: 'Employment Status',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: filterValue?.status ? filterValue?.status : 'WORKING',
      controlName: 'status',
      allowClear: true,
      input: 'select',
    },
    {
      order: 1,
      col: 'auto',
      className: 'ms-auto pe-0',
      input: 'button',
      type: 'dashed',
      htmlType: 'button',
      text: 'Reset',
    },
  ];

  const handleClick = (id, type, e) => {
    if (e) {
      e.stopPropagation();
    }
    if (type === 'edit') {
      navigate(`/add_user/${id}`);
    } else if (type === 'View') {
      navigate(`/user/view/${btoa(id)}/${btoa(buttonPermission?.edit)}`);
    } else if (type === 'delete') {
      callDelete(id);
      // navigate(`/user/view/${btoa(id)}`);
    } else if (type === 'login') {
      loginAsOtherUser(id);
    }
  };

  const loginAsOtherUser = async (userData) => {
    let reqData = {
      isAutoLogin: true,
      userRoleID: authState?.userRoleID || null,
      email: userData?.email || '',
    };
    if (authState?.userRoleID == 1 || authState?.userRoleID == 2) {
      try {
        let response = await LoginApi.login(reqData);
        if (response?.data?.success && response.data.result) {
          toastSuccess(response.data.message);
          let authAsAdmin = { ...response.data.result };
          authAsAdmin['switchUser'] = {
            email: authState?.email,
            userRoleID: authState?.userRoleID,
            switched: true,
          };
          await login({ ...authAsAdmin });
          navigate('/');
          window.location.reload();
        } else if (response?.data?.success) {
          toastInfo(response?.data?.message);
        } else {
          toastError(response?.data?.message);
        }
      } catch (error) {
        //toastError(error.response.data.message);
        toastError(error);
      }
    }
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const togglePopUp = () => {
    setEnable(!enable);
  };

  const SendCredent = () => {
    return (
      <>
        <div>Are you sure you want to send credentials via mail?</div>
        <div className="mt-4 antdBtn text-center">
          <Button
            type="default"
            onClick={() => setModal(!modal)}
            htmlType="button"
          >
            Cancel
          </Button>
          <Button type="primary" onClick={sendCredentails}>
            Send
          </Button>
        </div>
      </>
    );
  };

  const EnablePopUp = () => {
    return (
      <>
        <div>
          Are you sure you want to {ActionType?.type} the {ActionType?.subType}?
        </div>
        <div className="mt-4 antdBtn text-center">
          <Button
            type="default"
            onClick={() => setEnable(!enable)}
            htmlType="button"
          >
            Cancel
          </Button>
          <Button type="primary">Send</Button>
        </div>
      </>
    );
  };

  const sendCredentails = async () => {
    try {
      const response = await UserApi.sendBulkCredentials({ id: checkId });
      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        setCheckId([]);
      } else {
        toastInfo(response?.data?.message);
      }
      toggleModal();
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  const callDelete = async (id) => {
    await DeletePopup(async (canDelete) => {
      if (canDelete) {
        try {
          const response = await UserApi.delete({ id });
          if (response.data.success) {
            getUserList();
            toastSuccess(response.data.message);
          } else {
            toastWarn(response.data.message);
          }
        } catch (error) {
          //toastError(error.response.data.message);
          toastError(error);
        }
      }
    });
  };

  const handleAddUser = () => {
    navigate('/add_user');
  };

  const handleFilterChange = (obj, type) => {
    // filterValue = { ...filterValue, [keys]: e };
    // filterValue = obj || {};
    filterValue = { ...filterValue, ...obj } || {};
    getUserList(filterValue);
  };

  const getFilterList = async () => {
    try {
      let department = await Department.dropDown({});
      let designation = await Designation.dropDown({});
      let userRole = await UserRole.dropDownUserRole({ select: true });
      setFilterList((prevState) => ({
        ...prevState,
        department: department?.data?.result,
        designation: designation?.data?.result,
        userRole: userRole?.data?.result,
      }));
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  const getUserList = async (data) => {
    try {
      let response = await UserApi.getAllUsers(data);
      if (response?.data?.success) {
        let res = [];
        res = response.data.result;
        setUsers(res);
        setLoading(true);
      } else {
        toastError(response?.data?.message);
        return;
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  const handleButtonPermission = () => {
    if (!data?.checked) {
      navigate('/');
    }
    for (const element of data?.actions) {
      const keyName = element?.statename?.toLowerCase();
      setButtonPermissions((prevState) => ({
        ...prevState,
        [keyName]: element?.checked,
      }));
    }
  };
  const handleSearch = (e) => {
    let value = e.target.value.trim();

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      handleFilterChange({ ...filterValue, search: value });
    }, 400);
  };

  const handleEnableModel = (type, subType) => {
    ActionType = {
      type,
      subType,
    };

    setEnable(!enable);
  };

  const handleEnable = [
    {
      key: '1',
      label: (
        <div
          onClick={() =>
            handleEnableModel(ACTION_TYPE.ENABLE, ACTION_SUBTYPE.USER)
          }
        >
          User
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div
          onClick={() =>
            handleEnableModel(ACTION_TYPE.ENABLE, ACTION_SUBTYPE.NOTIFICATION)
          }
        >
          Notification
        </div>
      ),
    },
  ];
  const handleDisable = [
    {
      key: '1',
      label: (
        <div
          onClick={() =>
            handleEnableModel(ACTION_TYPE.DISABLE, ACTION_SUBTYPE.USER)
          }
        >
          User
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div
          onClick={() =>
            handleEnableModel(ACTION_TYPE.DISABLE, ACTION_SUBTYPE.NOTIFICATION)
          }
        >
          Notification
        </div>
      ),
    },
  ];

  useEffect(() => {
    getUserList(filterValue);
    getFilterList();
    if (data) {
      handleButtonPermission();
    }
    // return () => {
    //   filterValue = {
    //     status: 'WORKING',
    //     departmentID: '',
    //     designationID: '',
    //     search: '',
    //   };
    // };
  }, [data]);

  return (
    <>
      <div>
        <div className="d-md-flex">
          <div>
            <div className="">
              <div>
                <h5 className="text-nowrap me-2">User</h5>
              </div>
            </div>
          </div>

          <div className="ms-md-auto">
            <div className="d-md-flex">
              <div className="me-md-2 ">
                {' '}
                <Search
                  allowClear
                  onChange={handleSearch}
                  placeholder="Search "
                />
              </div>{' '}
              <div className="mt-2 d-flex justify-content-between mt-md-0">
                <div className="me-md-2">
                  {' '}
                  <Button type="dashed" htmlType="button" onClick={toggleModal}>
                    <div className="d-flex ">
                      <div>
                        <IoIosMail className="p-0 m-0 " size={16} />
                      </div>
                      <div style={{ paddingTop: '2px' }} className="ms-1  ">
                        Send Credentials
                      </div>
                    </div>
                  </Button>
                </div>
                <div>
                  {buttonPermission?.add && (
                    <Button
                      type="primary"
                      title="Add"
                      htmlType="button"
                      onClick={handleAddUser}
                      icon={<MdAdd size={20} />}
                    ></Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {loading && (
          <div className="mt-3">
            <Row>
              <Col>
                <div>
                  <TmFilter
                    filterArray={filterArray}
                    handleFilterChange={handleFilterChange}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div>
                  <UserManagementTable
                    setCheckId={setCheckId}
                    checkId={checkId}
                    buttonPermission={buttonPermission}
                    handleClick={handleClick}
                    columns={columns}
                    rows={users}
                    sendCredentails={sendCredentails}
                  />
                </div>
              </Col>
            </Row>
          </div>
        )}
        {!loading && <Loader />}
        <AntdModalComp
          width="30%"
          toggleModal={toggleModal}
          modalOpen={modal}
          modalHeader={<>Send Credentials</>}
          modalBody={<SendCredent setModal={setModal} />}
        />
        <AntdModalComp
          width="30%"
          toggleModal={togglePopUp}
          modalOpen={enable}
          modalHeader={<>Confirm Action?</>}
          modalBody={<EnablePopUp setEnable={setEnable} />}
        />
      </div>
    </>
  );
}

export default UserManagement;

import React, { useEffect, useState } from 'react';
import style from '../../Styles/Project.module.css';
import styles from '../../Styles/AddUser.module.css';
import { CardBody, Card, Row, Col, Table } from 'reactstrap';
import { PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Select,
  DatePicker,
  Upload,
  Image,
  Button,
  Checkbox,
  InputNumber,
  Dropdown,
  Menu,
} from 'antd';
import TmTableInput from '../ReusableComponents/TmTableInput';
import TmButton from '../ReusableComponents/TmButton';
import Mandatory from '../ReusableComponents/Mandatory';
import dayjs from 'dayjs';
import { ProjectApi } from '../../Api/ProjectApi';
import {
  toastError,
  toastInfo,
  toastSuccess,
  toastWarn,
} from '../Toast/ToastComponent';
import { useNavigate, useParams } from 'react-router';
import {
  DateFormat,
  BILLING_METHODS,
  DisplayDateFormat,
  HISTORY_INPUTS,
  CURRENCY_TYPE,
  CURRENCY_SYMBOL,
} from '../../constant/constant';
import UserApi from '../../Api/UserApi';
import { dayjsToDateFormat } from '../../ReusableFn/reusableFn';
import { FaArrowLeft, FaRegCircleXmark } from 'react-icons/fa6';
import { usePageAccess } from '../../helpers/handlePageAccess';
import { useSelector } from 'react-redux';
import { getProjects } from '../../store/reducers/actionPermission';
import Department from '../../Api/Department';
import { useAuth } from '../../Context/AuthContext';
import ModalComponent from '../ReusableComponents/ModalComponent';
import HistoryModal from '../ReusableComponents/HistoryModal';
import { BsFillHSquareFill } from 'react-icons/bs';
import EditFieldModal from '../ReusableComponents/EditFieldModal';
import { FiEdit } from 'react-icons/fi';
import { MdAdd } from 'react-icons/md';
import UpdateHistory from './UpdateHistory';

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

let initialValues = {
  billing_method: null,
  customer_name: null,
  description: null,
  end_date: null,
  fileInfo: null,
  project_managers: null,
  project_members: [],
  project_name: null,
  customModule: true,
  start_date: null,
  currencyType: 'INR (₹)',
};

const AddProject = () => {
  const { authState } = useAuth();
  const pageAccess = usePageAccess();
  const data = useSelector(getProjects);
  const [form] = Form.useForm();
  const [authoriry, setAuthority] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  // const [previewImage, setPreviewImage] = useState({ count: 0, url: "" });
  const [fileList, setFileList] = useState([]);
  const { edit_id, view_type } = useParams();
  const navigate = useNavigate();

  const startDate = Form.useWatch('start_date', form);
  const endDate = Form.useWatch('end_date', form);

  const currencyType = Form.useWatch('currencyType', form);

  const billingMethod = Form.useWatch('billing_method', form);

  const [members, setMembers] = useState([]);

  const projectManagers = Form.useWatch('project_managers', form);

  const [department, setDepartment] = useState([]);

  const [modal, setModal] = useState({
    modalLabel: undefined,
    isOpen: false,
    key: undefined,
  });

  const [newModal, setNewModal] = useState({
    isOpen: false,
    fields: undefined,
  });

  const [newModal2, setNewModal2] = useState({
    isOpen: false,
    field: undefined,
    index: null,
  });

  const [dropdownItems, setDropdownItems] = useState({
    estimatedHours: [],
    estimatedCost: [],
    startDate: [],
    endDate: [],
  });

  const [check, setCheck] = useState([]);

  const [orginalValues, setOrginalValues] = useState(undefined);

  const [historyData, setHistoryData] = useState([]);

  const [arrayData, setArrayData] = useState({
    value: [],
    count: 1,
  });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewOpen(true);
  };
  const handleImageChange = (event) => {
    if (event.file.status !== 'removed') {
      let file = event?.file;

      const validtypes = ['image/jpeg', 'image/jpg', 'image/png'];

      let totalSizeKB = file.size / Math.pow(1024, 1);
      if (totalSizeKB > 10000) {
        toastWarn('Please Upload Below 10 MB', 'Invalid');
        return;
      }

      if (validtypes.includes(file?.type)) {
        setFileList(event?.fileList);
      } else {
        toastWarn('Invalid file type. Only JPG and PNG files are allowed');
      }
    }
  };
  const getDropDown = async () => {
    try {
      let authoriry = await UserApi.dropDownAuthority({});
      let employee = await UserApi.dropDownEmployee({});
      let department = await Department.dropDown();
      if (authoriry?.data?.success && employee?.data?.success) {
        setAuthority(authoriry?.data?.result);
        setEmployee(employee?.data?.result);
        setDepartment(department?.data?.result);
      }
      if (edit_id) {
        getProjectById(edit_id);
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  const handleRemove = () => {
    setFileList([]);
  };

  const getProjectById = async (edit_id) => {
    try {
      const response = await ProjectApi.getProjectByID(atob(edit_id));
      if (response?.data?.success) {
        const res = response?.data?.result[0];
        let editValues = {
          ...res,
          start_date: res['start_date']
            ? dayjs(dayjs(res['start_date']).format(DateFormat), DateFormat)
            : '',
          end_date: res['end_date']
            ? dayjs(dayjs(res['end_date']).format(DateFormat), DateFormat)
            : '',
          project_managers: res?.project_managers
            ? JSON.parse(res?.project_managers)
            : null,
          department: res.department_name,
          project_members: res.project_members.length
            ? res.project_members.map((itm) => ({ ...itm }))
            : [],
          arrayData: res?.arrayData || null,
          currencyType: res?.currencyType || null,
        };

        //History
        setOrginalValues(editValues);

        if (editValues?.arrayData?.length) {
          setArrayData((prev) => ({
            ...prev,
            value: editValues?.arrayData,
            count: 1,
          }));
        } else {
          let checkArray = [];

          let obj = {
            start_date: {
              value: editValues?.start_date,
              oldValue: editValues?.start_date,
              fieldType: 'start_date',
              label: 'Start Date',
              isModified: false,
              isEdit: false,
              reason: '',
              isDateField: true,
            },
            end_date: {
              value: editValues?.end_date,
              oldValue: editValues?.end_date,
              fieldType: 'end_date',
              label: 'End Date',
              minDate: editValues?.start_date,
              isModified: false,
              isEdit: false,
              reason: '',
              isDateField: true,
            },
            estimatedHours: {
              value: editValues?.estimatedHours,
              oldValue: editValues?.estimatedHours,
              addedValue: editValues?.estimatedHours,
              fieldType: 'estimatedHours',
              label: 'Estimated Hours',
              isModified: false,
              isEdit: false,
              reason: '',
              isDateField: false,
            },
            currencyType: {
              value: editValues?.currencyType,
              oldValue: editValues?.currencyType,
              fieldType: 'currencyType',
              label: 'Currency Type',
              isModified: false,
              isEdit: false,
              reason: '',
              isDateField: true,
            },
            estimatedCost: {
              value: editValues?.estimatedCost,
              oldValue: editValues?.estimatedCost,
              addedValue: editValues?.estimatedCost,
              fieldType: 'estimatedCost',
              label: 'Estimated Cost',
              isModified: false,
              isEdit: false,
              reason: '',
              isDateField: false,
            },
            reason: {
              value: '',
            },
            createdAt: {
              value: res['createdAt']
                ? dayjs(dayjs(res['createdAt']).format(DateFormat), DateFormat)
                : '',
            },
          };
          checkArray.push(obj);
          setArrayData((prev) => ({
            ...prev,
            value: checkArray,
            count: 1,
          }));
        }

        //History

        form.setFieldsValue(editValues);

        setFileList(res?.fileInfo ? res?.fileInfo : []);

        // form.get
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  const onSubmit = async (reqData) => {
    reqData.project_members = reqData?.project_members.length
      ? reqData?.project_members.map((item) => item?.id)
      : '';

    let formData = new FormData();

    formData.append('user_id', authState?.id);
    formData.append('billing_method', reqData.billing_method);
    formData.append('customer_name', reqData.customer_name);
    formData.append('description', reqData.description);

    formData.append(
      'department',
      reqData?.department ? reqData?.department : '',
    );

    if (fileList.length) {
      fileList[0].hasOwnProperty('originFileObj') &&
        formData.append('file', fileList[0].originFileObj);
    }

    if (edit_id && !fileList?.length) {
      formData.append('fileDelete', true);
    }

    formData.append(
      'project_managers',
      reqData.project_managers ? JSON.stringify(reqData.project_managers) : '',
    );
    formData.append('project_name', reqData.project_name);
    formData.append('customModule', reqData.customModule);

    let { end_date, estimatedCost, estimatedHours, currencyType } = reqData;

    if (edit_id) {
      const filteredData = Object.fromEntries(
        Object.entries(arrayData?.value[arrayData?.value?.length - 1]).filter(
          ([key, value]) => value.isModified === true,
        ),
      );

      if (filteredData?.end_date) {
        end_date = filteredData?.end_date?.value
          ? filteredData?.end_date?.value
          : filteredData?.end_date?.oldValue;
      }

      if (filteredData?.estimatedHours) {
        estimatedHours = filteredData?.estimatedHours?.value
          ? filteredData?.estimatedHours?.value
          : filteredData?.estimatedHours?.oldValue;
      }

      if (filteredData?.estimatedCost) {
        estimatedCost = filteredData?.estimatedCost?.value
          ? filteredData?.estimatedCost?.value
          : filteredData?.estimatedCost?.oldValue;
      }

      if (filteredData?.currencyType) {
        currencyType =
          filteredData?.currencyType?.value &&
          filteredData?.estimatedCost?.value
            ? filteredData?.currencyType?.value
            : filteredData?.currencyType?.oldValue;
      }
    }

    // if (historyData?.length) {
    //   historyData.forEach(({ fieldType, updatedValue }) => {
    //     if (fieldType === 'end_date') end_date = updatedValue;
    //     if (fieldType === 'estimatedHours') estimatedHours = updatedValue;
    //     if (fieldType === 'estimatedCost') estimatedCost = updatedValue;
    //   });
    // }

    formData.append('estimatedHours', estimatedHours);
    formData.append('currencyType', currencyType);

    formData.append('estimatedCost', estimatedCost);
    formData.append('end_date', end_date ? dayjsToDateFormat(end_date) : '');

    formData.append(
      'project_members',
      reqData.project_members ? JSON.stringify(reqData.project_members) : '',
    );
    formData.append(
      'start_date',
      reqData?.start_date ? dayjsToDateFormat(reqData?.start_date) : '',
    );

    if (edit_id) {
      arrayData?.value.forEach((obj) => {
        Object.values(obj).forEach((value) => {
          if (typeof value === 'object' && value !== null) {
            value.isModified = false;
          }
        });
      });

      formData.append('arrayData', JSON.stringify(arrayData?.value));

      historyData?.length &&
        formData.append('historyData', JSON.stringify(historyData));

      formData.append('id', atob(edit_id));
    }

    try {
      const response = edit_id
        ? await ProjectApi.update(formData)
        : await ProjectApi.createProject(formData);
      if (response.data.success) {
        toastSuccess(response.data.message);
        navigate(`/projects/${view_type}`);
        form.resetFields();
      } else {
        toastWarn(response.data.message);
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  // const onSubmit = async (reqData) => {
  //   let hoursDecrease = false;

  //   if (historyData?.length) {
  //     for (const element of historyData) {
  //       if (
  //         [
  //           'start_date',
  //           'end_date',
  //           'estimatedHours',
  //           'estimatedCost',
  //         ].includes(element?.fieldType)
  //       ) {
  //         if (
  //           element?.fieldType == 'estimatedHours' &&
  //           orginalValues?.totalModule &&
  //           orginalValues?.estimatedHours > reqData?.estimatedHours
  //         ) {
  //           hoursDecrease = true;
  //         }
  //         element['updatedValue'] = reqData[element?.fieldType];
  //         element.updatedBy = Number(authState?.id);
  //       }
  //     }
  //   }

  //   if (hoursDecrease) {
  //     toastInfo(
  //       'This project has modules estimated hours can only be increased, not decreased.',
  //     );
  //     return;
  //   }
  //   reqData.project_members = reqData?.project_members.length
  //     ? reqData?.project_members.map((item) => item?.id)
  //     : '';

  //   let formData = new FormData();

  //   formData.append('user_id', authState?.id);
  //   formData.append('billing_method', reqData.billing_method);
  //   formData.append('customer_name', reqData.customer_name);
  //   formData.append('description', reqData.description);
  //   formData.append(
  //     'end_date',
  //     reqData?.end_date ? dayjsToDateFormat(reqData?.end_date) : '',
  //   );

  //   formData.append(
  //     'department',
  //     reqData?.department ? reqData?.department : '',
  //   );

  //   if (fileList.length) {
  //     fileList[0].hasOwnProperty('originFileObj') &&
  //       formData.append('file', fileList[0].originFileObj);
  //   }

  //   if (edit_id && !fileList?.length) {
  //     formData.append('fileDelete', true);
  //   }

  //   formData.append(
  //     'project_managers',
  //     reqData.project_managers ? JSON.stringify(reqData.project_managers) : '',
  //   );
  //   formData.append('project_name', reqData.project_name);
  //   formData.append('customModule', reqData.customModule);

  //   formData.append('estimatedHours', reqData.estimatedHours);

  //   formData.append('estimatedCost', reqData.estimatedCost);

  //   formData.append(
  //     'project_members',
  //     reqData.project_members ? JSON.stringify(reqData.project_members) : '',
  //   );
  //   formData.append(
  //     'start_date',
  //     reqData?.start_date ? dayjsToDateFormat(reqData?.start_date) : '',
  //   );

  //   if (edit_id) {
  //     historyData?.length &&
  //       formData.append('historyData', JSON.stringify(historyData));
  //     formData.append('id', atob(edit_id));
  //   }

  //   try {
  //     const response = edit_id
  //       ? await ProjectApi.update(formData)
  //       : await ProjectApi.createProject(formData);
  //     if (response.data.success) {
  //       toastSuccess(response.data.message);
  //       navigate(`/projects/${view_type}`);
  //       form.resetFields();
  //     } else {
  //       toastWarn(response.data.message);
  //     }
  //   } catch (error) {
  //     //toastError(error.response.data.message);
  //     toastError(error);
  //   }
  // };

  const customizeRequiredMark = (label, { required }) => (
    <>
      {label}
      {required && <Mandatory />}
    </>
  );

  useEffect(() => {
    if (data) {
      pageAccess(data, edit_id);
    }
  }, [data]);

  const validateInput = (_, value) => {
    if (value !== value.trim() || value.includes('  ')) {
      return Promise.reject(new Error(' Extra spaces are not allowed'));
    }

    return Promise.resolve();
  };

  const toggleNewModal = (data) => {
    setNewModal((prev) => ({
      ...prev,
      isOpen: !prev.isOpen,
      fields: data,
    }));
  };

  const toggleNewModal2 = (index) => {
    let accessData =
      arrayData?.value[index ? index - 1 : arrayData?.value?.length - 1];

    let newObj = {
      start_date: {
        value: null,
        oldValue: accessData?.start_date?.value
          ? accessData?.start_date?.value
          : accessData?.start_date?.oldValue,
        fieldType: 'start_date',
        label: 'Start Date',
        isModified: false,
        isEdit: true,
        reason: '',
        isDateField: true,
      },
      end_date: {
        value: null,
        oldValue: accessData?.end_date?.value
          ? accessData?.end_date?.value
          : accessData?.end_date?.oldValue,
        fieldType: 'end_date',
        minDate: null,
        label: 'End Date',
        isModified: false,
        isEdit: true,
        reason: '',
        isDateField: true,
      },
      estimatedHours: {
        value: null,
        // value: accessData?.estimatedHours?.value
        //   ? accessData?.estimatedHours?.value
        //   : accessData?.estimatedHours?.oldValue,
        oldValue: accessData?.estimatedHours?.value
          ? accessData?.estimatedHours?.value
          : accessData?.estimatedHours?.oldValue,
        addedValue: null,
        fieldType: 'estimatedHours',
        label: 'Estimated Hours',
        isModified: false,
        isEdit: true,
        reason: '',
        isDateField: false,
      },
      currencyType: {
        value: null,
        oldValue: accessData?.currencyType?.value
          ? accessData?.currencyType?.value
          : accessData?.currencyType?.oldValue,
        fieldType: 'currencyType',
        label: 'Currency Type',
        isModified: false,
        isEdit: true,
        reason: '',
        isDateField: true,
      },
      estimatedCost: {
        value: null,
        oldValue: accessData?.estimatedCost?.value
          ? accessData?.estimatedCost?.value
          : accessData?.estimatedCost?.oldValue,
        addedValue: null,
        fieldType: 'estimatedCost',
        label: 'Estimated Cost',
        isModified: false,
        isEdit: true,
        reason: '',
        isDateField: false,
      },
      reason: {
        value: '',
      },
      createdAt: {
        value: dayjs().format(DateFormat),
      },
    };

    if (
      !orginalValues?.arrayData &&
      arrayData?.value?.length == 2 &&
      !index &&
      index !== 0
    ) {
      toastInfo('Values have not been updated yet.');
      return;
    }

    if (
      orginalValues?.arrayData?.length + 1 == arrayData?.value?.length &&
      !index &&
      index !== 0
    ) {
      toastInfo('Values have not been updated yet.');
      return;
    }

    setNewModal2((prev) => ({
      ...prev,
      isOpen: !prev.isOpen,
      field: !index && index !== 0 ? newObj : arrayData?.value[index],
      index: !index && index !== 0 ? arrayData?.value?.length : index,
    }));
  };

  const toggleModal = (data, label, key) => {
    if (!label) {
      let value = orginalValues[modal.key];
      form.setFieldValue(modal?.key, value);
    }

    let hasKey = historyData?.some((element) => {
      if (HISTORY_INPUTS.includes(key) && element.fieldType == key) {
        return true;
      }
    });

    if (!hasKey) {
      setModal((prev) => ({
        ...prev,
        modalLabel: label,
        isOpen: !prev.isOpen,
        key,
      }));
    }
  };

  const handleHistoryData = (key, value) => {
    let array = [...historyData];
    let indexValue = array.findIndex((element) => element.fieldType == key);
    indexValue !== -1 && array.splice(indexValue, 1);
    value && array.push(value);
    setHistoryData(array);
    // setModal({ modalLabel: undefined, isOpen: false, key: undefined });
  };

  const handleRevert = async (key) => {
    form.setFieldValue(key, orginalValues[key]);
    handleHistoryData(key);
  };

  const isConditionMet = (data) => {
    return data && data.isBefore(endDate, 'day');
  };

  const getProjectHistory = async () => {
    try {
      const response = await ProjectApi.getProjectHistoryTable({
        project_id: atob(edit_id),
      });
      if (response?.data?.success) {
        const { projectHistory } = response.data.result;
        setCheck(projectHistory);
      }
    } catch (error) {
      toastError(error);
    }
  };

  useEffect(() => {
    getDropDown();
    if (edit_id) {
      getProjectHistory();
    }
  }, []);

  const getMenuItems = (type) => {
    let isThere = check?.some((element) => element?.logType === type);

    const dropdownItems = isThere
      ? [
          {
            key: '1',
            label: <b>History</b>,
          },
          {
            key: '2',
            label: (
              <div className={style.historyScrolable}>
                <ul>
                  {check?.map((item) => {
                    if (item?.logType === type) {
                      return (
                        <li key={item?.id} className="p-2">
                          <b>{item?.logType}</b> - {item?.logContent}{' '}
                          <span style={{ color: '#646871' }}>
                            -{' '}
                            {dayjs(
                              item.createdAt,
                              'DD-MM-YYYY h:mm A',
                            ).fromNow()}
                          </span>
                        </li>
                      );
                    }
                    return null;
                  })}
                </ul>
              </div>
            ),
          },
        ]
      : [];

    return dropdownItems;
  };

  const appendData = () => {
    let checkArray = [...arrayData?.value];

    let obj = {
      start_date: {
        value: null,
        fieldType: 'start_date',
        label: 'Start Date',
        isModified: false,
        isEdit: true,
        reason: '',
        isDateField: true,
      },
      end_date: {
        value: null,
        fieldType: 'end_date',
        minDate: orginalValues?.start_date,
        label: 'End Date',
        isModified: false,
        isEdit: true,
        reason: '',
        isDateField: true,
      },
      estimatedHours: {
        value: null,
        addedValue: null,
        fieldType: 'estimatedHours',
        label: 'Estimated Hours',
        isModified: false,
        isEdit: true,
        reason: '',
        isDateField: false,
      },
      estimatedCost: {
        value: null,
        addedValue: null,
        fieldType: 'estimatedCost',
        label: 'Estimated Cost',
        isModified: false,
        isEdit: true,
        reason: '',
        isDateField: false,
      },
    };

    checkArray.push(obj);

    if (arrayData?.count == 1) {
      setArrayData((prev) => ({
        ...prev,
        value: checkArray,
        count: 2,
      }));
    } else {
      toastInfo('Values have not been updated yet.');
    }
  };

  return (
    <div>
      <div className="d-flex  px-0 pb-1">
        <div
          className={`${styles['arrow-icon']} border px-2 pb-1`}
          onClick={() => navigate(`/projects/${view_type}`)}
        >
          <FaArrowLeft />
        </div>
        <div className={`mx-3 ${styles['add-user']}`}>
          {edit_id ? 'Edit Project' : 'Add Project'}
        </div>
      </div>
      <Row className="my-2">
        <Col>
          <Card>
            <CardBody>
              <Form
                initialValues={initialValues}
                requiredMark={customizeRequiredMark}
                form={form}
                onFinish={onSubmit}
                layout="vertical"
              >
                <div className="row">
                  <div className="col-12">
                    <div className="mb-2">
                      <span className={`fw-bold ${style['title-font-size']}`}>
                        General Details
                      </span>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <Form.Item
                          name="project_name"
                          label="Project Name"
                          rules={[
                            {
                              required: true,

                              message: 'This field is required',
                            },
                            {
                              validator: validateInput,
                            },
                          ]}
                        >
                          <Input placeholder="Enter Project Name" />
                        </Form.Item>
                      </div>
                      <div className="col-6">
                        <Form.Item
                          name="customer_name"
                          label="Customer Name"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                            {
                              validator: validateInput,
                            },
                          ]}
                        >
                          <Input placeholder="Enter Customer Name" />
                        </Form.Item>
                      </div>{' '}
                    </div>

                    <div className="row">
                      <div className="col-6">
                        {' '}
                        <Form.Item label="Department" name="department">
                          <Select
                            allowClear
                            type="select"
                            placeholder="Select"
                            showSearch
                          >
                            {department.map((element) => (
                              <Option
                                key={element.value}
                                value={element?.value}
                                disabled={!element.status}
                              >
                                {element?.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="col-6">
                        {' '}
                        <Form.Item
                          label="Billing Method"
                          name="billing_method"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                          <Select
                            disabled={edit_id}
                            allowClear
                            options={BILLING_METHODS}
                            type="select"
                            placeholder="Select"
                            showSearch
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        {' '}
                        <Form.Item
                          label="Project Manager"
                          name="project_managers"
                          // rules={[
                          //   { required: true, message: "This field is required" },
                          // ]}
                        >
                          <Select
                            mode="multiple"
                            placeholder="Select"
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {authoriry?.map(
                              (element) =>
                                !members?.includes(element?.id) && (
                                  <Option key={element?.id} value={element?.id}>
                                    {element.value}
                                  </Option>
                                ),
                            )}

                            {/* {authoriry?.map((element) => (
                          <Option key={element.id} value={element.id}>
                            {element.value}
                          </Option>
                        ))} */}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="col-6">
                        <Form.Item name="description" label="Description">
                          <TextArea placeholder="Enter Description" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6"></div>
                      <div className="col-6"></div>
                    </div>

                    <div className={`${edit_id ? 'd-none' : 'row'}`}>
                      <div className="col-6 ">
                        <div className="row">
                          <div className="col">
                            {' '}
                            <div className="d-flex align-items-center">
                              <Form.Item
                                className="m-0"
                                label="Start Date"
                                name={'start_date'}
                                rules={[
                                  ...(Form.useWatch('billing_method', form) !==
                                    'FREE' &&
                                  (edit_id ? orginalValues?.start_date : true)
                                    ? [
                                        {
                                          required: true,
                                          message: 'This field is required',
                                        },
                                      ]
                                    : []),
                                ]}
                              >
                                <DatePicker
                                  maxDate={endDate}
                                  placeholder="Start Date"
                                  style={{ width: '100%' }}
                                  onChange={
                                    edit_id
                                      ? (data) =>
                                          toggleModal(
                                            data,
                                            'start date',
                                            'start_date',
                                          )
                                      : undefined
                                  }
                                  format={DisplayDateFormat}
                                />
                              </Form.Item>

                              {edit_id && (
                                <Button
                                  onClick={() => handleRevert('start_date')}
                                  title="Revert"
                                  className="border-0 m-0  bg-transparent pt-3 pt-3 p-1 m-0"
                                  disabled={
                                    !historyData?.some(
                                      (element) =>
                                        element?.fieldType == 'start_date',
                                    )
                                  }
                                >
                                  <FaRegCircleXmark size={18} />
                                </Button>
                              )}
                            </div>
                            {getMenuItems('START DATE')?.length > 0 &&
                              edit_id && (
                                <div className="mt-1">
                                  <span className="supported_text">
                                    <Dropdown
                                      overlay={
                                        <Menu
                                          items={getMenuItems('START DATE')}
                                        />
                                      }
                                      // menu={{ items: dropdownItems?.startDate }}
                                      placement="topLeft"
                                      arrow
                                    >
                                      <button
                                        className="btn m-0 p-0 border-none"
                                        type="button"
                                      >
                                        <BsFillHSquareFill
                                          size={16}
                                          color="#a1b0d3"
                                        />
                                        <small>&nbsp;History</small>
                                      </button>
                                    </Dropdown>
                                  </span>
                                </div>
                              )}
                          </div>
                          <div className="col">
                            <div className="d-flex align-items-center">
                              <Form.Item
                                className="m-0"
                                label="End Date"
                                name="end_date"
                                rules={[
                                  ...(Form.useWatch('billing_method', form) !==
                                    'FREE' &&
                                  (edit_id ? orginalValues?.end_date : true)
                                    ? [
                                        {
                                          required: true,
                                          message: 'This field is required',
                                        },
                                      ]
                                    : []),
                                ]}
                              >
                                <DatePicker
                                  minDate={startDate}
                                  placeholder="End Date"
                                  style={{ width: '100%' }}
                                  onChange={
                                    edit_id
                                      ? (data) =>
                                          toggleModal(
                                            data,
                                            'end date',
                                            'end_date',
                                          )
                                      : undefined
                                  }
                                  format={DisplayDateFormat}
                                />
                              </Form.Item>
                              {edit_id && (
                                <Button
                                  onClick={() => handleRevert('end_date')}
                                  title="Revert"
                                  className="border-0 bg-transparent pt-3 p-1 m-0"
                                  disabled={
                                    !historyData?.some(
                                      (element) =>
                                        element?.fieldType == 'end_date',
                                    )
                                  }
                                >
                                  <FaRegCircleXmark size={18} />
                                </Button>
                              )}
                            </div>
                            {getMenuItems('END DATE')?.length > 0 &&
                              edit_id && (
                                <div className="mt-1">
                                  <span className="supported_text">
                                    <Dropdown
                                      overlay={
                                        <Menu
                                          items={getMenuItems('END DATE')}
                                        />
                                      }
                                      //menu={{ items: dropdownItems?.endDate }}
                                      placement="topLeft"
                                      arrow
                                    >
                                      <button
                                        className="btn m-0 p-0 border-none"
                                        type="button"
                                      >
                                        <BsFillHSquareFill
                                          size={16}
                                          color="#a1b0d3"
                                        />
                                        <small>&nbsp;History</small>
                                      </button>
                                    </Dropdown>
                                  </span>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="row">
                          <div className="col">
                            <div className="d-flex align-items-center">
                              <Form.Item
                                name="estimatedHours"
                                label="Estimated Hours"
                                className="w-100 m-0"
                                rules={[
                                  ...(Form.useWatch('billing_method', form) !==
                                    'FREE' &&
                                  (edit_id
                                    ? orginalValues?.estimatedHours
                                    : true)
                                    ? [
                                        {
                                          required: true,
                                          message: 'This field is required',
                                        },
                                      ]
                                    : []),
                                  {
                                    type: 'number',
                                    min: 0,
                                    message: 'Invalid Number',
                                  },
                                ]}
                              >
                                <InputNumber
                                  className="w-100 m-0"
                                  placeholder="Estimated Hours"
                                  onChange={
                                    edit_id
                                      ? (data) =>
                                          toggleModal(
                                            data,
                                            'estimated hours',
                                            'estimatedHours',
                                          )
                                      : undefined
                                  }
                                  type="number"
                                />
                              </Form.Item>

                              {edit_id && (
                                <Button
                                  onClick={() => handleRevert('estimatedHours')}
                                  title="Revert"
                                  className="border-0 bg-transparent pt-3 p-1 m-0"
                                  disabled={
                                    !historyData?.some(
                                      (element) =>
                                        element?.fieldType == 'estimatedHours',
                                    )
                                  }
                                >
                                  <FaRegCircleXmark size={18} />
                                </Button>
                              )}
                            </div>
                            {getMenuItems('ESTIMATED HOURS')?.length > 0 &&
                              edit_id && (
                                <div className="mt-1">
                                  <span className="supported_text">
                                    <Dropdown
                                      overlay={
                                        <Menu
                                          items={getMenuItems(
                                            'ESTIMATED HOURS',
                                          )}
                                        />
                                      }
                                      // menu={{
                                      //   items: dropdownItems?.estimatedHours,
                                      // }}
                                      placement="topLeft"
                                      arrow
                                    >
                                      <button
                                        className="btn m-0 p-0 border-none"
                                        type="button"
                                      >
                                        <BsFillHSquareFill
                                          size={16}
                                          color="#a1b0d3"
                                        />
                                        <small>&nbsp;History</small>
                                      </button>
                                    </Dropdown>
                                  </span>
                                </div>
                              )}
                          </div>{' '}
                          <div className="col">
                            <div className="row">
                              <div className="col">
                                <Form.Item
                                  label="Estimated Cost"
                                  name="currencyType"
                                >
                                  <Select
                                    // allowClear
                                    options={CURRENCY_TYPE}
                                    type="select"
                                    placeholder="Select"
                                    showSearch
                                  />
                                </Form.Item>
                              </div>
                              <div className="col">
                                <div className="d-flex align-items-center">
                                  <Form.Item
                                    name="estimatedCost"
                                    label=" "
                                    className="w-100 m-0"
                                    rules={[
                                      // ...(Form.useWatch('billing_method', form) !==
                                      // 'FREE'
                                      //   ? [
                                      //       {
                                      //         required: true,
                                      //         message: 'This field is required',
                                      //       },
                                      //     ]
                                      //   : []),
                                      {
                                        type: 'number',
                                        min: 0,
                                        message: 'Invalid Number',
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      className="w-100 m-0"
                                      placeholder="Estimated Cost"
                                      onChange={
                                        edit_id
                                          ? (data) =>
                                              toggleModal(
                                                data,
                                                'estimated cost',
                                                'estimatedCost',
                                              )
                                          : undefined
                                      }
                                      type="number"
                                    />
                                  </Form.Item>

                                  {edit_id && (
                                    <Button
                                      onClick={() =>
                                        handleRevert('estimatedCost')
                                      }
                                      title="Revert"
                                      className="border-0 bg-transparent pt-3 p-1 m-0"
                                      disabled={
                                        !historyData?.some(
                                          (element) =>
                                            element?.fieldType ==
                                            'estimatedCost',
                                        )
                                      }
                                    >
                                      <FaRegCircleXmark size={18} />
                                    </Button>
                                  )}
                                </div>
                                {getMenuItems('ESTIMATED COST')?.length > 0 &&
                                  edit_id && (
                                    <div className="mt-1">
                                      <span className="supported_text">
                                        <Dropdown
                                          overlay={
                                            <Menu
                                              items={getMenuItems(
                                                'ESTIMATED COST',
                                              )}
                                            />
                                          }
                                          // menu={{ items: dropdownItems?.estimatedCost }}
                                          placement="topLeft"
                                          arrow
                                        >
                                          <button
                                            className="btn m-0 p-0 border-none"
                                            type="button"
                                          >
                                            <BsFillHSquareFill
                                              size={16}
                                              color="#a1b0d3"
                                            />
                                            <small>&nbsp;History</small>
                                          </button>
                                        </Dropdown>
                                      </span>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={` mt-2 d-none  ${style.background4}`}>
                      {arrayData?.value?.length > 0 &&
                        arrayData?.value?.map((element, index) => (
                          <>
                            <div className="row p-2">
                              <div>
                                <div className="col-12 border-bottom mb-1 ">
                                  <div
                                    className={` row ${style.fourcardcolor}`}
                                  >
                                    <div
                                      className={`col-3  border-end   ${style.fourcardcolor}   `}
                                    >
                                      <div className=" ">
                                        <div>Start date</div>
                                        <div
                                          className={`${
                                            index !== 0
                                              ? style.colorforstartDate
                                              : ''
                                          }`}
                                        >
                                          <span>
                                            <b>
                                              {element?.start_date?.value
                                                ? dayjs(
                                                    element?.start_date?.value,
                                                  ).format('DD-MM-YYYY')
                                                : '--'}
                                            </b>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={`col-3  border-end   ${style.fourcardcolor}   `}
                                    >
                                      <div className="text-center">
                                        <div>End date</div>
                                        <div>
                                          <span>
                                            {' '}
                                            <b>
                                              {element?.end_date?.value
                                                ? dayjs(
                                                    element?.end_date?.value,
                                                  ).format('DD-MM-YYYY')
                                                : '--'}
                                            </b>
                                            <br></br>
                                          </span>
                                        </div>
                                      </div>
                                    </div>{' '}
                                    <div
                                      className={`col-3  border-end   ${style.fourcardcolor}   `}
                                    >
                                      <div className="text-center">
                                        <div>
                                          Estimated Hours{' '}
                                          {element?.estimatedHours?.value && (
                                            <span title="Added Value">
                                              {`(${element?.estimatedHours?.value})`}
                                            </span>
                                          )}
                                        </div>{' '}
                                        <div>
                                          <span>
                                            <b>
                                              {' '}
                                              {element?.estimatedHours
                                                ?.addedValue || '--'}
                                            </b>
                                            <br></br>
                                          </span>
                                        </div>
                                      </div>
                                    </div>{' '}
                                    <div
                                      className={`col-3     ${style.fourcardcolor}   `}
                                    >
                                      <div className="text-center">
                                        <div>
                                          Estimated Cost
                                          {element?.estimatedCost?.value && (
                                            <span title="Added Value">
                                              {`(${element?.estimatedCost?.value})`}
                                            </span>
                                          )}
                                        </div>
                                        <div>
                                          <span>
                                            {' '}
                                            <b>
                                              {element?.estimatedCost?.value ||
                                                '--'}
                                            </b>
                                            <br></br>
                                          </span>
                                        </div>
                                      </div>
                                      {index ===
                                        arrayData?.value?.length - 1 && (
                                        <Button
                                          onClick={() => toggleNewModal2(index)}
                                        >
                                          Edit
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </div>{' '}
                              </div>
                            </div>
                          </>
                        ))}
                    </div>

                    {/* <div className={` mt-2  ${style.background4}`}>
                      {arrayData?.value?.length > 0 &&
                        arrayData?.value?.map((element, index) => (
                          <>
                            <div className="row p-2">
                              <div>
                                <div className="col-12 border-bottom mb-1 ">
                                  <div
                                    className={` row ${style.fourcardcolor}`}
                                  >
                                    <div
                                      className={`col-3  border-end   ${style.fourcardcolor}   `}
                                    >
                                      <div className=" ">
                                        <div>Start date</div>
                                        <div className="">
                                          <span>
                                            <b>
                                              {element?.start_date?.value
                                                ? dayjs(
                                                  element?.start_date?.value,
                                                ).format('DD-MM-YYYY')
                                                : '--'}
                                            </b>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={`col-3  border-end   ${style.fourcardcolor}   `}
                                    >
                                      <div className="text-center">
                                        <div>End date</div>
                                        <div>
                                          <span>
                                            {' '}
                                            <b>
                                              {element?.end_date?.value
                                                ? dayjs(
                                                  element?.end_date?.value,
                                                ).format('DD-MM-YYYY')
                                                : '--'}
                                            </b>
                                            <br></br>
                                          </span>
                                          {element?.end_date?.isEdit && (
                                            <button
                                              type="button"
                                              onClick={() =>
                                                toggleNewModal(
                                                  element?.end_date,
                                                )
                                              }
                                              className="btn text-primary  btn-sm p-0"
                                            >
                                              Add
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>{' '}
                                    <div
                                      className={`col-3  border-end   ${style.fourcardcolor}   `}
                                    >
                                      <div className="text-center">
                                        <div>
                                          Est. Hrs{' '}
                                          {element?.estimatedHours?.value && (
                                            <span title="Added Value">
                                              {`(${element?.estimatedHours?.value})`}
                                            </span>
                                          )}
                                        </div>{' '}
                                        <div>
                                          <span>
                                            <b>
                                              {' '}
                                              {element?.estimatedHours
                                                ?.addedValue || '--'}
                                            </b>
                                            <br></br>
                                          </span>
                                          {element?.estimatedHours?.isEdit && (
                                            <button
                                              type="button"
                                              onClick={() =>
                                                toggleNewModal(
                                                  element?.estimatedHours,
                                                )
                                              }
                                              className="btn text-primary  btn-sm p-0"
                                            >
                                              Add
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>{' '}
                                    <div
                                      className={`col-3     ${style.fourcardcolor}   `}
                                    >
                                      <div className="text-center">
                                        <div>
                                          Est. Cost
                                          {element?.estimatedCost?.value && (
                                            <span title="Added Value">
                                              {`(${element?.estimatedCost?.value})`}
                                            </span>
                                          )}
                                        </div>
                                        <div>
                                          <span>
                                            {' '}
                                            <b>
                                              {element?.estimatedCost
                                                ?.addedValue || '--'}
                                            </b>
                                            <br></br>
                                          </span>
                                          {element?.estimatedCost?.isEdit && (
                                            <button
                                              onClick={() =>
                                                toggleNewModal(
                                                  element?.estimatedCost,
                                                )
                                              }
                                              type="button"
                                              className="btn text-primary  btn-sm p-0"
                                            >
                                              Add
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>{' '}
                              </div>
                            </div>
                          </>
                        ))}
                    </div> */}

                    <div className="row">
                      <div className="col">
                        {' '}
                        <Form.Item label="Add Company Logo">
                          <Upload
                            beforeUpload={(file) => {
                              return false;
                            }}
                            accept="image/png, image/jpeg"
                            maxCount={1}
                            fileList={fileList}
                            onPreview={handlePreview}
                            onChange={handleImageChange}
                            onRemove={handleRemove}
                            multiple={false}
                            listType="picture-card"
                          >
                            {fileList?.length === 0 && <PlusOutlined />}
                          </Upload>
                        </Form.Item>
                        {fileList?.length > 0 && (
                          <Image
                            wrapperStyle={{
                              display: 'none',
                            }}
                            preview={{
                              visible: previewOpen,
                              onVisibleChange: (visible) =>
                                setPreviewOpen(visible),
                            }}
                            src={fileList[0].url || fileList[0].preview}
                          />
                        )}
                      </div>
                      <div className="col">
                        {' '}
                        <Form.Item
                          name="customModule"
                          className="mt-3"
                          valuePropName="checked"
                        >
                          <Checkbox>Allow to Create Draft Modules</Checkbox>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  {arrayData?.value?.length > 0 && (
                    <Table>
                      <thead>
                        <tr>
                          <th style={{ color: '#888B99' }} className="col-1">
                            Start Date
                          </th>
                          <th style={{ color: '#888B99' }} className="col-1">
                            End Date
                          </th>
                          <th style={{ color: '#888B99' }} className="col-1">
                            Estimated Hours
                          </th>
                          <th style={{ color: '#888B99' }} className="col-1">
                            Estimated Cost
                          </th>
                          <th style={{ color: '#888B99' }} className="col-1">
                            Created Date
                          </th>
                          <th style={{ color: '#888B99' }} className="col-3">
                            Reason
                          </th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {arrayData?.value?.map((element, index) => (
                          <>
                            {' '}
                            <tr className="mt-2">
                              <td className="">
                                {' '}
                                <div
                                  className={`d-flex  align-items-center  border-end ${style.tablehieght}`}
                                >
                                  <b
                                    style={{
                                      color:
                                        index !== 0 ? '#888B99' : '#080808',
                                    }}
                                  >
                                    {element?.start_date?.value
                                      ? dayjs(
                                          element?.start_date?.value,
                                        ).format('DD-MM-YYYY')
                                      : '--'}
                                  </b>
                                </div>
                              </td>
                              <td>
                                <div
                                  className={`border-end d-flex  align-items-center ${style.tablehieght} `}
                                >
                                  {element?.end_date?.value ? (
                                    <b>
                                      {dayjs(element?.end_date?.value).format(
                                        'DD-MM-YYYY',
                                      )}
                                    </b>
                                  ) : (
                                    <span
                                      style={{
                                        color: '#888B99',
                                      }}
                                    >
                                      {element?.end_date?.oldValue
                                        ? dayjs(
                                            element?.end_date?.oldValue,
                                          ).format('DD-MM-YYYY')
                                        : '--'}
                                    </span>
                                  )}

                                  {/* {element?.end_date?.value
                                      ? dayjs(element?.end_date?.value).format(
                                          'DD-MM-YYYY',
                                        )
                                      : '--'} */}
                                </div>
                              </td>
                              <td>
                                {' '}
                                <div
                                  className={`d-flex  align-items-center    border-end  ${style.tablehieght}`}
                                >
                                  {element?.estimatedHours?.value &&
                                  element?.estimatedHours?.value !==
                                    element?.estimatedHours?.oldValue ? (
                                    <b>
                                      {`${element?.estimatedHours?.value} hrs`}
                                    </b>
                                  ) : (
                                    <span
                                      style={{
                                        color: '#888B99',
                                      }}
                                    >
                                      {element?.estimatedHours?.oldValue
                                        ? `${element?.estimatedHours?.oldValue} hrs`
                                        : '--'}
                                    </span>
                                  )}

                                  {/* {element?.estimatedHours?.value ? (
                                    <b>
                                      {`${element?.estimatedHours?.value} hrs`}
                                    </b>
                                  ) : (
                                    '--'
                                  )} */}
                                </div>
                              </td>
                              {/* <td>
                                <div
                                  className={`border-end ${style.tablehieght}`}
                                >
                                  <div className="d-flex  ">
                                    <div
                                      className={`text-center ${style.addedValues}`}
                                    >
                                      Added Hrs
                                    </div>
                                    {element?.estimatedHours?.value && (
                                      <span
                                        className="ps-1"
                                        title="Added Value"
                                      >
                                        <b>
                                          {' '}
                                          {element?.estimatedHours?.value
                                            ? `${element?.estimatedHours?.value} hrs`
                                            : '--'}
                                        </b>
                                      </span>
                                    )}
                                  </div>
                                  <div className=" d-flex mt-1 ">
                                    <div
                                      className={` text-center  ${style.TotalValue}`}
                                    >
                                      Total Hrs
                                    </div>
                                    <span className="ps-1">
                                      <b>
                                        {element?.estimatedHours?.addedValue
                                          ? `${element?.estimatedHours?.addedValue} hrs`
                                          : '--'}
                                      </b>
                                    </span>
                                  </div>
                                </div>
                              </td> */}
                              <td>
                                {' '}
                                <div
                                  className={`d-flex  align-items-center    border-end ${style.tablehieght}`}
                                >
                                  {/* <b>
                                    {element?.estimatedCost?.value
                                      ? `${
                                          CURRENCY_SYMBOL[
                                            element?.currencyType?.value
                                          ] ?? ''
                                        } ${element?.estimatedCost?.value}`
                                      : '--'}
                                  </b> */}

                                  {element?.estimatedCost?.value ? (
                                    <b>{`${
                                      CURRENCY_SYMBOL[
                                        element?.currencyType?.value
                                      ] ?? ''
                                    } ${element?.estimatedCost?.value}`}</b>
                                  ) : (
                                    <span
                                      style={{
                                        color: '#888B99',
                                      }}
                                    >
                                      {element?.estimatedCost?.oldValue
                                        ? `${
                                            CURRENCY_SYMBOL[
                                              element?.currencyType?.oldValue
                                            ] ?? ''
                                          } ${element?.estimatedCost?.oldValue}`
                                        : '--'}
                                    </span>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div
                                  className={`border-end d-flex  align-items-center ${style.tablecreated} `}
                                >
                                  <b>
                                    {element?.createdAt?.value
                                      ? dayjs(element?.createdAt?.value).format(
                                          'DD-MM-YYYY',
                                        )
                                      : '--'}
                                  </b>
                                </div>
                              </td>
                              <td>
                                <div
                                  className={`  d-flex  align-items-center `}
                                >
                                  <div
                                    className={` text-wrap  ${style.tablecreatedReason}   `}
                                  >
                                    <b>
                                      {element?.reason?.value
                                        ? `${element?.reason?.value}`
                                        : '--'}
                                    </b>
                                  </div>

                                  {/* <div className="ms-auto ">
                                    {index === arrayData?.value?.length - 1 && (
                                      <button
                                        type="button"
                                        className="border-0 bg-transparent"
                                        onClick={() => toggleNewModal2(index)}
                                      >
                                        <FiEdit size={18} />
                                      </button>
                                    )}
                                  </div> */}
                                </div>
                              </td>
                            </tr>
                          </>
                        ))}{' '}
                      </tbody>
                    </Table>
                  )}
                  {edit_id && (
                    <div className="text-end mt-2">
                      <button
                        htmlType="button"
                        type="button"
                        className={`border-0  text-primary bg-transparent ${style.fontsizeAdd}`}
                        onClick={() => toggleNewModal2()}
                      >
                        <MdAdd
                          size={22}
                          className="border-primary border rounded-pill text-primary"
                        />{' '}
                        <span className="pt-2"> Add</span>
                      </button>
                    </div>
                  )}
                  <div className="row">
                    <div className="col">
                      <div className="mb-2">
                        <span className={`fw-bold ${style['title-font-size']}`}>
                          Team Members
                        </span>
                      </div>
                      <TmTableInput
                        form={form}
                        employee={employee}
                        projectManagers={projectManagers}
                        setMembers={setMembers}
                        members={members}
                      />
                    </div>
                  </div>
                </div>
                <Row className="mt-3">
                  <Col className="d-flex justify-content-center antdBtn">
                    <Button
                      type="default"
                      size="medium"
                      htmlType="button"
                      onClick={() => navigate(`/projects/${view_type}`)}
                    >
                      Cancel
                    </Button>
                    <Button type="primary" size="medium" htmlType="submit">
                      {edit_id ? 'Update' : 'Save'}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <div>
        <ModalComponent
          size="md"
          toggleModal={() => toggleModal()}
          modalOpen={modal?.isOpen}
          modalHeader={<>Reason</>}
          modalBody={
            <HistoryModal
              historyData={historyData}
              setHistoryData={setHistoryData}
              toggleModal={toggleModal}
              modal={modal}
              setModal={setModal}
              orginalValues={orginalValues}
              handleHistoryData={handleHistoryData}
            />
          }
        />
      </div>
      <div>
        <ModalComponent
          size="md"
          toggleModal={() => toggleNewModal()}
          modalOpen={newModal?.isOpen}
          modalHeader={<>Edit Field</>}
          modalBody={
            <EditFieldModal
              historyData={historyData}
              setHistoryData={setHistoryData}
              toggleModal={toggleNewModal}
              modal={newModal}
              setModal={setNewModal}
              orginalValues={orginalValues}
              handleHistoryData={handleHistoryData}
              arrayData={arrayData}
              setArrayData={setArrayData}
            />
          }
        />
      </div>
      <div>
        <ModalComponent
          size="md"
          toggleModal={() => toggleNewModal2('cancel')}
          modalOpen={newModal2?.isOpen}
          modalHeader={<>Edit Fields</>}
          modalBody={
            <UpdateHistory
              billingMethod={billingMethod}
              historyData={historyData}
              setHistoryData={setHistoryData}
              toggleModal={toggleNewModal2}
              modal={newModal2}
              setModal={setNewModal2}
              orginalValues={orginalValues}
              handleHistoryData={handleHistoryData}
              arrayData={arrayData}
              setArrayData={setArrayData}
            />
          }
        />
      </div>
    </div>
  );
};

export default AddProject;

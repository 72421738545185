import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  TimePicker,
  InputNumber,
} from 'antd';
import enUS from 'antd/es/locale/en_US';
import { useForm, Controller } from 'react-hook-form';
import TextArea from 'antd/es/input/TextArea';

import { Label, Row, Col } from 'reactstrap';
import dayjs from 'dayjs';
import {
  CURRENCY_TYPE,
  DateFormat,
  DisplayDateFormat,
  HISTORY_INPUTS,
  LEAVETYPE,
  PERMISSION_PAGE_ROUTES,
} from '../../constant/constant';
import Mandatory from '../ReusableComponents/Mandatory';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import { LeaveManageApi } from '../../Api/LeaveManageApi';
import {
  toastError,
  toastInfo,
  toastSuccess,
  toastWarn,
} from '../Toast/ToastComponent';
import { useAuth } from '../../Context/AuthContext';
import { useParams } from 'react-router-dom';

const UpdateHistory = ({
  modalOpen,
  setModalOpen,
  setModal,
  modal,
  historyData,
  toggleModal,
  setArrayData,
  arrayData,
  isModule,
  handleHistoryData,
  orginalValues,
  setHistoryData,
  billingMethod,
  hours,
}) => {
  const [form] = Form.useForm();

  const { authState } = useAuth();
  const { edit_id, view_type } = useParams();

  const onSubmit = async (data) => {
    if (String(data['estimatedHours']).includes('-')) {
      data['estimatedHours'] =
        modal?.field['estimatedHours'].oldValue -
        Math.abs(data['estimatedHours']);
    } else {
      data['estimatedHours'] = data['estimatedHours']
        ? modal?.field['estimatedHours'].oldValue +
          Math.abs(data['estimatedHours'])
        : modal?.field['estimatedHours'].oldValue;
    }

    let balanceHours =
      parseFloat(hours?.balanceHours ?? '0') +
      (orginalValues?.estimated_hours
        ? parseFloat(orginalValues?.estimated_hours ?? '0')
        : parseFloat(orginalValues?.totalModuleHours ?? '0'));

    if (isModule && data['estimatedHours'] > balanceHours) {
      toastInfo('Estimated hours cannot be greater than the balance hours.');
      return;
    }

    if (
      isModule &&
      data['estimatedHours'] &&
      orginalValues?.totalModuleHours > data['estimatedHours']
    ) {
      toastInfo(
        `This module has been logged for ${orginalValues?.totalModuleHours} hours, the estimated hours cannot be lower`,
      );
      return;
    }

    if (
      !isModule &&
      orginalValues?.totalProjectHours > data['estimatedHours']
    ) {
      toastInfo(
        `This project has been logged for ${orginalValues?.totalProjectHours} hours, the estimated hours cannot be lower`,
      );
      return;
    }
    for (const element in modal?.field) {
      if (element !== 'createdAt') {
        modal.field[element].value = data[element];
        modal.field[element].reason = data['reason'];

        if (['start_date', 'end_date'].includes(element)) {
          if (
            !dayjs(modal.field[element].oldValue).isSame(dayjs(data[element]))
          ) {
            modal.field[element].isModified = true;
          }
        } else {
          if (modal.field[element].oldValue !== data[element]) {
            modal.field[element].isModified = true;
          }
        }
      }
    }

    let newArray = [...arrayData?.value];

    newArray.splice(modal?.index, 1, modal.field);

    setArrayData((prev) => ({
      ...prev,
      value: newArray,
      count: modal?.index == 0 ? 1 : 2,
    }));

    setModal((prev) => ({
      ...prev,
      isOpen: false,
      field: undefined,
      index: null,
    }));
  };

  // const onSubmit = async (data) => {
  //   const currentArray = modal?.field;

  //   let array = [];

  //   for (const element in data) {
  //     if (data[element] && element !== 'start_date' && element !== 'reason') {
  //       let obj = {
  //         reason: data?.reason,
  //         project_id: isModule ? orginalValues?.project_id : atob(edit_id),
  //         // ...(isModule && { module_id: orginalValues?.id }),
  //         fieldType: element,
  //         orginalValue:
  //           arrayData?.value[modal?.index == 0 ? 0 : modal?.index - 1][element]
  //             .addedValue,
  //         updatedBy: Number(authState?.id),
  //         updatedValue: HISTORY_INPUTS.includes(element)
  //           ? arrayData?.value[modal?.index == 0 ? 0 : modal?.index - 1][
  //               element
  //             ].addedValue + data[element]
  //           : data[element],
  //       };
  //       array.push(obj);
  //     }
  //   }

  //   if (modal?.index != 0) {
  //     setHistoryData(array);
  //   }

  // for (const element in modal?.field) {
  //   modal.field[element].value = data[element];
  //   modal.field[element].reason = data['reason'];
  //   if (HISTORY_INPUTS.includes(element) && modal?.index !== 0) {
  //     modal.field[element].addedValue = data[element]
  //       ? arrayData?.value[modal?.index - 1][element].addedValue +
  //         data[element]
  //       : null;
  //   }
  //   if (modal?.index == 0) {
  //     modal.field[element].addedValue = data[element];
  //   }
  // }
  // let newArray = [...arrayData?.value];

  // newArray.splice(modal?.index, 1, modal.field);

  // setArrayData((prev) => ({
  //   ...prev,
  //   value: newArray,
  //   count: modal?.index == 0 ? 1 : 2,
  // }));

  // setModal((prev) => ({
  //   ...prev,
  //   isOpen: false,
  //   field: undefined,
  //   index: null,
  // }));
  // };

  const customizeRequiredMark = (label, { required }) => (
    <>
      {label}
      {required && <Mandatory />}
    </>
  );

  useEffect(() => {
    const currentArray = modal?.field;
    let editValues = {
      start_date: arrayData.value[0]?.start_date?.value
        ? dayjs(
            dayjs(arrayData.value[0]?.start_date?.value).format(DateFormat),
            DateFormat,
          )
        : '',
      end_date: currentArray?.end_date?.value
        ? dayjs(
            dayjs(currentArray?.end_date?.value).format(DateFormat),
            DateFormat,
          )
        : '',
      estimatedCost: currentArray?.estimatedCost?.value,
      estimatedHours: currentArray?.estimatedHours?.value,
      reason: currentArray?.reason?.value,
      currencyType: currentArray?.currencyType?.value,
    };
    form.setFieldsValue(editValues);
  }, [arrayData, modal]);

  console.log('Saifk RAJA', orginalValues);

  return (
    <>
      <div>
        <Form
          layout="vertical"
          form={form}
          onFinish={onSubmit}
          requiredMark={customizeRequiredMark}
        >
          <Row>
            <Col>
              <Form.Item className="m-0" label="Start Date" name={'start_date'}>
                <DatePicker
                  disabled={true}
                  // maxDate={endDate}
                  placeholder="Start Date"
                  style={{ width: '100%' }}
                  // onChange={
                  //   edit_id
                  //     ? (data) => toggleModal(data, 'start date', 'start_date')
                  //     : undefined
                  // }
                  format={DisplayDateFormat}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item className="m-0" label="End Date" name={'end_date'}>
                <DatePicker
                  minDate={Form.useWatch('start_date', form)}
                  placeholder="End Date"
                  style={{ width: '100%' }}
                  // onChange={
                  //   edit_id
                  //     ? (data) => toggleModal(data, 'start date', 'start_date')
                  //     : undefined
                  // }
                  format={DisplayDateFormat}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col className="pt-2">
              <Form.Item
                name="estimatedHours"
                label="Estimated Hours"
                className="w-100 m-0"
                rules={[
                  ...(billingMethod !== 'FREE' && modal?.index == 0 && !isModule
                    ? [
                        {
                          required: true,
                          message: 'This field is required',
                        },
                      ]
                    : []),
                  // {
                  //   type: 'number',
                  //   min: 0,
                  //   message: 'Invalid Number',
                  // },
                ]}
              >
                <InputNumber
                  className="w-100 m-0"
                  placeholder="Estimated Hours"
                  type="number"
                />
              </Form.Item>
              <div className="d-flex align-items-baseline ">
                <b className="m-1">Note:</b>{' '}
                <p style={{ marginTop: 8, color: '#6c757d', fontSize: 12 }}>
                  <span>
                    Enter negative values to deduct estimated hours (e.g., -20).
                  </span>
                </p>
              </div>
            </Col>
            <Col className={`pt-2 ${isModule ? 'd-none' : ''}`}>
              <div>
                <label>Estimated Cost </label>
              </div>
              <Row className="pt-2">
                <Col>
                  <Form.Item name="currencyType">
                    <Select
                      // allowClear
                      options={CURRENCY_TYPE}
                      type="select"
                      placeholder="Select"
                      showSearch
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="estimatedCost" className="w-100  m-0">
                    <InputNumber
                      className="w-100 m-0"
                      placeholder="Estimated Cost"
                      type="number"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="reason"
                label={`Reason`}
                rules={[
                  ...(modal?.index !== 0
                    ? [
                        {
                          required: true,
                          message: 'This field is required',
                        },
                      ]
                    : []),
                ]}
              >
                <Input.TextArea
                  disabled={modal?.index == 0}
                  rows={4}
                  placeholder="Maximum 200 characters"
                  maxLength={200}
                />
              </Form.Item>
              <div className="text-center antdBtn">
                <Button onClick={toggleModal} type="default" htmlType="button">
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default UpdateHistory;

export const GENDER = [
  { value: 'MALE', label: 'Male', id: 1 },
  { value: 'FEMALE', label: 'Female', id: 2 },
  { value: 'OTHERS', label: 'Others', id: 3 },
];

export const BG_STATUS = [
  { value: 'POSITIVE', label: 'Positive', id: 0 },
  { value: 'NEGATIVE', label: 'Negative', id: 1 },
];

export const EMPLOYEE_TYPE = [
  { value: 'PART-TIME', label: 'Part-Time', id: 0 },
  { value: 'FULL-TIME', label: 'Full-Time', id: 1 },
  { value: 'WORKFROMHOME', label: 'Work From Home', id: 3 },
];

export const EMPLOYEE_MODE = [
  {
    value: 'BOND',
    label: 'Bond',
    id: 0,
  },
  { value: 'NON-BOND', label: 'Non-Bond', id: 1 },
];

export const SERVICE_STATUS = [
  { value: 'YES', label: 'Yes', id: 0 },
  { value: 'NO', label: 'No', id: 1 },
];

export const EMPLOYEE_STATUS = [
  {
    value: 'WORKING',
    label: 'Working',
    id: 0,
  },
  {
    value: 'NOT WORKING',
    label: 'Not Working',
    id: 1,
  },
];

export const EMPLOYEE_STATUS_FILTER = [
  {
    value: 'WORKING',
    label: 'Working',
    id: 0,
  },
  {
    value: 'NOT WORKING',
    label: 'Not Working',
    id: 1,
  },
];

export const STATUSMAIL = [
  {
    value: true,
    label: 'Active',
  },
  {
    value: false,
    label: 'In Active',
  },
];

export const USERTIMESHEET_STATUS = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'Approved',
    label: 'Approved',
  },

  {
    value: 'Pending',
    label: 'Pending',
  },
];

export const DAY_TYPES = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'MISSED',
    label: 'Missed Days',
  },
  {
    value: 'LOCKED',
    label: 'Locked Days',
  },
];

export const EVENT_TYPE = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'MISSED',
    label: 'Timesheet Missed ',
  },
  {
    value: 'LOCKED',
    label: 'Timesheet Locked ',
  },
  {
    value: 'APPROVED',
    label: 'Timesheet Approved',
  },
  {
    value: 'REJECTED',
    label: 'Timesheet Rejected',
  },
  {
    value: 'UNLOCKED',
    label: 'Timesheet Unlocked',
  },
  {
    value: 'LEAVE SUBMISSION',
    label: 'Leave Submission',
  },
  {
    value: 'LEAVE UPDATION',
    label: 'Leave Updation',
  },
  {
    value: 'LEAVE APPROVAL',
    label: 'Leave Approval',
  },
  {
    value: 'LEAVE REJECTION',
    label: 'Leave Rejection',
  },
  {
    value: 'LEAVE DELETION',
    label: 'Leave Deletion',
  },
  {
    value: 'PERMISSION SUBMISSION',
    label: 'Permission Submission',
  },
  {
    value: 'PERMISSION UPDATION',
    label: 'Permission Updation',
  },
  {
    value: 'PERMISSION APPROVAL',
    label: 'Permission Approval',
  },
  {
    value: 'PERMISSION REJECTION',
    label: 'Permission Rejection',
  },
  {
    value: 'PERMISSION DELETION',
    label: 'Permission Deletion',
  },
];

export const EMPLOYEE_EXIT_STATUS = [
  {
    value: 'RELIEVED',
    label: 'Relieved',
    id: 0,
  },
  { value: 'ABSCOND', label: 'Abscond', id: 1 },
  { value: 'TERMINATED', label: 'Terminated', id: 2 },
];
export const PROBATION_STATUS = [
  {
    value: 'CONFIRMED',
    label: 'Confirmed',
    id: 0,
  },
  {
    value: 'NOTCONFIRMED',
    label: 'Not Confirmed',
    id: 1,
  },
];
export const SETTLEMENT_STATUS = [
  {
    value: 'PAID',
    label: 'Paid',
    id: 0,
  },
  {
    value: 'NOTPAID',
    label: 'Not Paid',
    id: 1,
  },
];

export const SETTLEMENT_ELGIBILITY_STATUS = [
  {
    value: 'ELIGIBLE',
    label: 'Eligible',
    id: 0,
  },
  {
    value: 'NOT_ELIGIBLE',
    label: 'Not eligible',
    id: 1,
  },
];

export const MODALTITLE = {
  ADDMODAL: 'Add',
  EDITMODAL: 'Edit',
};
export const MONTHS = [
  { label: 'January', value: 'January' },
  { label: 'February', value: 'February' },
  { label: 'March', value: 'March' },
  { label: 'April', value: 'April' },
  { label: 'May', value: 'May' },
  { label: 'June', value: 'June' },
  { label: 'July', value: 'July' },
  { label: 'August', value: 'August' },
  { label: 'September', value: 'September' },
  { label: 'October', value: 'October' },
  { label: 'November', value: 'November' },
  { label: 'December', value: 'December' },
];
export const MONTHS_INDEX = [
  { label: 'January', value: 0 },
  { label: 'February', value: 1 },
  { label: 'March', value: 2 },
  { label: 'April', value: 3 },
  { label: 'May', value: 4 },
  { label: 'June', value: 5 },
  { label: 'July', value: 6 },
  { label: 'August', value: 7 },
  { label: 'September', value: 8 },
  { label: 'October', value: 9 },
  { label: 'November', value: 10 },
  { label: 'December', value: 11 },
];

export const YEARS = [
  { label: 2020, value: 2020 },
  { label: 2021, value: 2021 },
  { label: 2022, value: 2022 },
  { label: 2023, value: 2023 },
  { label: 2024, value: 2024 },
  { label: 2025, value: 2025 },
  { label: 2026, value: 2026 },
  { label: 2027, value: 2027 },
  { label: 2028, value: 2028 },
  { label: 2029, value: 2029 },
  { label: 2030, value: 2030 },
  { label: 2031, value: 2031 },
  { label: 2032, value: 2032 },
  { label: 2033, value: 2033 },
];

export const SORTING = [
  { label: 'Descending', value: 'DESC' },
  { label: 'Ascending', value: 'ASC' },
];

export const STATUS = [
  { label: 'Completed', value: 'COMPLETED' },
  { label: 'Inprogress', value: 'inProgress' },
  { label: 'Not Yet Started', value: 'notYetStarted' },
  { label: 'Over Due', value: 'overDue' },
];

export const LEAVETYPE = [
  { label: 'Casual Leave', value: 'Casual leave' },
  { label: 'Sick Leave', value: 'Sick leave' },
  { label: 'Maternity Leave', value: 'Maternity leave' },
  { label: 'Paternity Leave', value: 'Paternity leave' },
  { label: 'Marriage Leave', value: 'Marriage leave' },
];

export const PERMISSION_TYPE = [
  { label: 'Morning', value: 'Morning' },
  { label: 'Evening', value: 'Evening' },
];

export const LEAVE_STATUS = [
  { label: 'Approved', value: 'APPROVED' },
  { label: 'Rejected', value: 'REJECTED' },
  { label: 'Pending', value: 'PENDING' },
];

export const CurrentDate = () => {
  return new Date();
};

export const SelectYear = {
  options: YEARS,
  optionFilterProp: 'label',
  placeholder: 'Year',
  order: 1,
  col: 1,
  mode: '',
  maxTagCount: '',
  defaultValue: YEARS[0].value,
};
export const SelectMonths = {
  options: MONTHS,
  optionFilterProp: 'label',
  placeholder: 'Month',
  order: 1,
  col: 1,
  mode: '',
  maxTagCount: '',
  defaultValue: MONTHS[0].value,
};
export const SelectSorting = {
  options: SORTING,
  optionFilterProp: 'label',
  placeholder: 'Sorting',
  order: 1,
  col: 2,
  mode: '',
  maxTagCount: '',
  defaultValue: SORTING[0].value,
};
export const DateFormat = 'YYYY-MM-DD';
export const DisplayDateFormat = 'DD-MM-YYYY';

export const PaginationRange = {
  startRange: 10,
  endRange: 10,
  initalStart: 10,
  pageSize: 10,
  setPageSize: function (val) {
    this.pageSize = val;
    this.startRange = val;
    this.endRange = val;
    this.initalStart = val;
  },
};

export const PaginationCheck = {
  startRange: 5,
  endRange: 5,
  initalStart: 5,
  pageSize: 5,
};

export const BILLING_METHODS = [
  { label: 'Fixed cost for project', value: 'FIXED COST FOR PROJECT', id: 1 },
  { label: 'Time and Demand', value: 'TIME AND DEMAND', id: 2 },
  { label: 'Service Based', value: 'SERVICE BASED', id: 3 },
  { label: 'Free', value: 'FREE', id: 3 },
  // { label: 'Based on project hours', value: 'BASED ON PROJECT HOURS', id: 2 },
  // { label: 'Based on task hours', value: 'BASED ON TASK HOURS', id: 3 },
];

export const MODULE_STATUS = [
  { label: 'Not yet started', value: 'NOT YET STARTED', id: 1 },
  { label: 'In progress', value: 'INPROGRESS', id: 2 },
  { label: 'Completed', value: 'COMPLETED', id: 3 },
];

export const ACTIVE_STATUS = [
  { label: 'Active', value: true, id: 1 },
  { label: 'Inactive', value: false, id: 2 },
];

export const TASK_BREAK_FEILDS = [
  { label: 'Lunch Break', value: 'Lunch Break' },
  { label: 'Evening Break', value: 'Evening Break' },
];

export const TASK_PERMISSIONS = [{ label: 'Permission', value: 'Permission' }];
export const LEAVES = [
  { label: 'First Half', value: 'First Half' },
  { label: 'Second Half', value: 'Second Half' },
];

export const NORECORD = 'No Records Found';

export const CONFIRMATIONWEEKEND = {
  title: 'Mark As Weekend',
  content:
    "Marking as a weekend will remove all entries on the selected date. Click 'Proceed' to confirm.",
  okText: 'Proceed',
  okType: 'primary',
  cancelText: 'Cancel',
};
export const CONFIRMATIONHOLIDAY = {
  title: 'Mark As Public Holiday',
  content:
    "Marking as a public holiday will remove all entries on the selected date. Click 'Proceed' to confirm.",
  okText: 'Proceed',
  okType: 'primary',
  cancelText: 'Cancel',
};
export const CONFIRMATIONFULLDAY = {
  title: 'Full Day Leave',
  content:
    "Selecting 'Full Day Leave' will remove all entries on the selected date. Click 'Proceed' to confirm.",
  okText: 'Proceed',
  okType: 'primary',
  cancelText: 'Cancel',
};

export const CONFIRM_UNLOCK = {
  title: 'Unlock',
  content: 'Are you sure you need to unlock?',
  okText: 'Unlock',
  okType: 'primary',
  cancelText: 'Cancel',
};

export const EMAIL_NOTIFICATIONS = [
  { label: 'Forget Password', value: 'FORGET PASSWORD' },
  { label: 'Lock Remainder', value: 'LOCK TIMESHEET' },
  { label: 'Timesheet Remainder', value: 'LOCK REMAINDER' },
  { label: 'Registration', value: 'USER CREATION' },
  { label: 'Permission Submission', value: 'PERMISSION SUBMISSION' },
  { label: 'Permission Approval', value: 'PERMISSION APPROVAL' },
  { label: 'Permission Rejection', value: 'PERMISSION REJECTION' },
  { label: 'Leave Submission', value: 'LEAVE SUBMISSION' },
  { label: 'Leave Approval', value: 'LEAVE APPROVAL' },
  { label: 'Leave Rejection', value: 'LEAVE REJECTION' },
  { label: 'Month Approval', value: 'MONTH APPROVAL' },
];

export const notificationStatus = [
  {
    value: true,
    label: 'Delivered',
  },
  {
    value: false,
    label: 'Not Delivered',
  },
];
export const PERMISSION_VALUE = 2;

export const MODAL_HEIGHT = {
  auto: 'auto',
  sm: '360px',
  md: '510px',
};

export const ACTION_TYPE = {
  ENABLE: 'enable',
  DISABLE: 'disable',
};

export const ACTION_SUBTYPE = {
  USER: 'users',
  NOTIFICATION: 'notifications',
};

export const ALPHABET_COLORS = {
  a: '#d0a0d0', // Light purple
  b: '#87cefa', // Light blue
  c: '#90ee90', // Light green
  d: '#dcdcdc', // Light grayish yellow
  e: '#d0a0d0', // Light purple (same as 'a')
  f: '#ffb347', // Light orange
  g: '#ffcccb', // Light pink
  h: '#a52a2a', // Light brown (more reddish)
  i: '#e0ffff', // Light cyan
  j: '#e6a1e1', // Light magenta
  k: '#d0f0c0', // Light lime
  l: '#d0d0ff', // Light indigo
  m: '#66cdaa', // Light teal
  n: '#4682b4', // Light navy
  o: '#bdb76b', // Light olive
  p: '#d3d3d3', // Light gray
  q: '#c04000', // Light maroon
  r: '#c0c0c0', // Light silver
  s: '#a9a9a9', // Light dark gray
  t: '#ffd700', // Light gold
  u: '#f0ffff', // Light azure
  v: '#d8aadd', // Light purple (same as 'a' and 'e')
  w: '#b0b0b0', // Light charcoal
  x: '#f08080', // Light crimson
  y: '#98fb98', // Light emerald
  z: '#6ca0dc', // Light sapphire
};

export const PERMISSION_PAGE_ROUTES = [
  'my_requests_permission',
  'user_requests_permission',
];
export const GENERAL_REGEX = {
  password: /^[^\s].+[^\s]$|^[^\s]$/,
};
export const TIMESHEET_PAGE_ROUTES = ['my_user_timesheet'];

export const MINIMUM_COLUMN_SELECT = 4;

export const WITHOUT_CONFIG = 'NO_CONFIG';

export const HISTORY_INPUTS = [
  'estimatedHours',
  // 'estimatedCost',
  'estimated_hours',
];

export const PROJECT_EVENTS = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'ESTIMATED HOURS',
    label: 'Estimated hours ',
  },
  {
    value: 'ESTIMATED COST',
    label: 'Estimated cost ',
  },
  {
    value: 'START DATE',
    label: 'Start date',
  },
  {
    value: 'END DATE',
    label: 'End date',
  },
  {
    value: 'DEACTIVATED',
    label: 'Deactivated',
  },
  {
    value: 'ACTIVATED',
    label: 'Activated',
  },
  {
    value: 'CREATION',
    label: 'Creation',
  },
];

export const CURRENCY_TYPE = [
  {
    label: 'INR (₹)',
    value: 'INR (₹)',
    symbol: '₹',
    id: 1,
  },
  {
    label: 'SGD (S$)',
    value: 'SGD (S$)',
    symbol: 'S$',
    id: 2,
  },
  {
    label: 'AED (د.إ)',
    value: 'AED (د.إ)',
    symbol: 'د.إ ',
    id: 3,
  },
  {
    label: 'USD ($)',
    value: 'USD ($)',
    symbol: '$',
    id: 4,
  },
  {
    label: 'MYR (RM)',
    value: 'MYR (RM)',
    symbol: 'RM',
    id: 5,
  },
  {
    label: 'AUD ($)',
    value: 'AUD ($)',
    symbol: '$',
    id: 6,
  },
];

export const CURRENCY_SYMBOL = {
  'INR (₹)': '₹',
  'AUD ($)': '$',
  'MYR (RM)': 'RM',
  'USD ($)': '$',
  'AED (د.إ)': 'AED',
  'SGD (S$)': 'S$',
};

import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';
import { FaRegCircleXmark } from 'react-icons/fa6';
import { FiEdit } from 'react-icons/fi';
import { IoEyeOutline } from 'react-icons/io5';
import {
  MdDeleteOutline,
  MdOutlineClose,
  MdOutlineComment,
  MdOutlineDone,
  MdOutlineDoneOutline,
} from 'react-icons/md';
import { TiTickOutline } from 'react-icons/ti';
import { Label } from 'reactstrap';

const ActionButton = ({ item, handleClick, hideBtn }) => {
  return (
    <div className="d-flex">
      {/* <div className="" >
        {' '}
        <button
          className="icon-hover btn m-0 p-0"
          onClick={() => handleClick(item, 'View')}
          title="View"
        >
          <IoEyeOutline size={18} />
        </button>
      </div>  */}
      {hideBtn?.approve && (
        <div>
          {' '}
          <button
            className="icon-hover text-success   btn p-0"
            onClick={() => handleClick(item, 'approve')}
            title="Accept"
          >
            <MdOutlineDone size={20} />
          </button>
        </div>
      )}
      {hideBtn?.reject && (
        <div>
          {' '}
          <button
            className="icon-hover text-danger btn p-0"
            title="Reject"
            onClick={() => handleClick(item, 'reject')}
          >
            <MdOutlineClose size={20} />
          </button>
        </div>
      )}
      {hideBtn?.edit && (
        <div>
          <button
            className="icon-hover btn p-0"
            onClick={() => handleClick(item, 'Edit')}
            title="Edit"
          >
            <FiEdit size={16} />
          </button>
        </div>
      )}{' '}
      {hideBtn?.comment && (
        <div>
          <button
            className="icon-hover btn p-0"
            onClick={() => handleClick(item, 'Comment')}
            title="Comment"
          >
            <MdOutlineComment size={18} />
          </button>
        </div>
      )}{' '}
      {hideBtn?.delete && (
        <div className="">
          <button
            className="icon-hover btn p-0"
            onClick={() => handleClick(item, 'Delete')}
            title="Delete"
          >
            <MdDeleteOutline size={20} />
          </button>
        </div>
      )}
    </div>
  );
};

export default ActionButton;

import React, { useEffect, useRef, useState } from 'react';

import { Button, Input } from 'antd';
import { LuDownload } from 'react-icons/lu';
import TmFilter from '../ReusableComponents/TmFilter';
import {
  LEAVE_STATUS,
  LEAVETYPE,
  MONTHS,
  PERMISSION_PAGE_ROUTES,
  PERMISSION_TYPE,
} from '../../constant/constant';
import LeaveManagementTable from './LeaveManagementTable';
import {
  userRequestPermissionHeaders,
  userRequestsLeaveHeaders,
} from '../../constant/tableHeader';
import { FiEdit } from 'react-icons/fi';
import { MdDeleteOutline, MdOutlineComment } from 'react-icons/md';
import { LeaveManageApi } from '../../Api/LeaveManageApi';
import { useAuth } from '../../Context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { toastError, toastInfo, toastSuccess } from '../Toast/ToastComponent';
import {
  DeleteApprovedPopup,
  DeletePopup,
} from '../ReusableComponents/DeletePopup';
import ModalComponent from '../ReusableComponents/ModalComponent';
import CommentModal from './CommentModal';
import {
  dayjsToDateFormat,
  generateCurrentMonth,
  generateCurrentYear,
  generateYears,
  GET_CURRENT_MONTH_IDX,
} from '../../ReusableFn/reusableFn';
import Loader from '../ReusableComponents/Loader';
import { useSelector } from 'react-redux';
import {
  getUserLeave,
  getUserPermission,
} from '../../store/reducers/actionPermission';
import { Permissions } from '../../Api/Permisisons';
import useFilterStateData from '../../customHooks/useFilterStateData';

let modalData;

const { Search } = Input;

const UserRequests = () => {
  const debounceTimeoutRef = useRef(null);

  const { pathname } = useLocation();

  const [isPermissionComp, setIsPermissionComp] = useState(false);

  const [loading, setLoading] = useState(false);
  const { month: filterStateMonth, year: filterStateYear } =
    useFilterStateData();
  const [filterValue, setfilterValue] = useState({
    type: '',
    leave_status: '',
    date: '',
    month: filterStateMonth,
    year: filterStateYear,
    search: '',
  });
  // let filterValue = {
  //   type: '',
  //   leave_status: '',
  //   date: '',
  //   month: filterStateMonth,
  //   year: filterStateYear,
  //   search: '',
  // };
  const filterArray = [
    {
      options: PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1])
        ? PERMISSION_TYPE
        : LEAVETYPE,
      optionFilterProp: 'label',
      placeholder: PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1])
        ? 'Permission Type'
        : 'Leave Type',
      order: 1,
      col: 2,
      mode: 'multiple',
      maxTagCount: 1,
      defaultValue: null,
      controlName: 'type',
      allowClear: true,
      input: 'select',
    },
    {
      options: LEAVE_STATUS,
      optionFilterProp: 'label',
      placeholder: 'Status',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: null,
      controlName: 'leave_status',
      allowClear: true,
      input: 'select',
    },
    {
      options: MONTHS,
      optionFilterProp: 'label',
      placeholder: 'Month',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: filterStateMonth,
      controlName: 'month',
      allowClear: true,
      input: 'select',
    },
    {
      options: generateYears(),
      optionFilterProp: 'label',
      placeholder: 'Year',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: filterStateYear,
      controlName: 'year',
      allowClear: true,
      input: 'select',
    },
    {
      input: 'date',
      minDate: '',
      maxDate: '',
      order: 1,
      col: 2,
      defaultValue: null,
      controlName: 'date',
      allowClear: true,
      placeholder: 'DD-MM-YYYY',
    },
    {
      order: 1,
      col: 'auto',
      className: 'ms-auto pe-0',
      input: 'button',
      type: 'dashed',
      htmlType: 'button',
      text: 'Reset',
    },
  ];

  const handleFilterChange = (obj, type) => {
    if (type === 'date') {
      obj['date'] = obj['date'] ? dayjsToDateFormat(obj['date']) : '';
    }
    // filterValue = { ...filterValue, [keys]: e };
    setfilterValue((pre) => ({ ...pre, ...obj }));
    // filterValue = { ...obj } || {};
    getLeaveManageList({ ...filterValue, ...obj });
  };

  const [leaveList, setLeaveList] = useState([]);

  const data = useSelector(
    PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1])
      ? getUserPermission
      : getUserLeave,
  );

  const [buttonPermission, setButtonPermissions] = useState({});

  const handleButtonPermission = () => {
    if (!data?.checked) {
      navigate('/');
    }
    for (const element of data?.actions) {
      const keyName = element?.statename?.toLowerCase();
      setButtonPermissions((prevState) => ({
        ...prevState,
        [keyName]: element?.checked,
      }));
    }
  };

  const [modal, setModal] = useState(false);

  const { authState } = useAuth();

  const getLeaveManageList = async (object) => {
    try {
      let obj = {
        ...(object ? object : filterValue),
        user_id: authState.id,
        userRoleID: authState.userRoleID,
      };
      const response = PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1])
        ? await Permissions.getUserList(obj)
        : await LeaveManageApi.getUserList(obj);
      if (response.data.success) {
        setLoading(true);

        setLeaveList(response.data.result);
      } else {
        toastError(response.data.message);
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  const navigate = useNavigate();

  const handleEdit = (e) => {};

  const actions = (
    <span>
      <button className="icon-hover btn p-0" onClick={handleEdit}>
        <FiEdit size={18} />
      </button>
      <button className="icon-hover btn p-0" onClick={handleEdit}>
        <MdDeleteOutline size={20} />
      </button>
      <button className="icon-hover btn p-0" onClick={handleEdit}>
        <MdOutlineComment size={18} />
      </button>
    </span>
  );

  const callDelete = async (id, requestType) => {
    const checkStatus = leaveList?.filter((element) => element?.id == id);

    const handleDelete = async () => {
      try {
        const response = PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1])
          ? await Permissions.delete({
              id,
              requestType,
            })
          : await LeaveManageApi.delete({
              id,
              requestType,
            });
        if (response.data.success) {
          toastSuccess(response?.data?.message);
          getLeaveManageList();
        } else {
          toastInfo(response.data.message);
        }
      } catch (error) {
        //toastError(error.response.data.message);
        toastError(error);
      }
    };

    if (checkStatus[0].status == 'Approved') {
      await DeleteApprovedPopup(async (canDelete) => {
        if (canDelete) {
          await handleDelete();
        }
      });
    } else {
      await DeletePopup(async (canDelete) => {
        if (canDelete) {
          await handleDelete();
        }
      });
    }
  };
  const handleApprove = async (id, value) => {
    let obj = {
      id: id,
      user_id: authState.id,
      status: value,
    };

    try {
      const response = PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1])
        ? await Permissions.approve(obj)
        : await LeaveManageApi.approve(obj);
      if (response.data.success) {
        getLeaveManageList();
        toastSuccess(response?.data?.message);
      } else {
        toastInfo(response.data.message);
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  const handleEditData = async (id) => {
    try {
      const response = PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1])
        ? await Permissions.getById(id)
        : await LeaveManageApi.getById(id);
      if (response?.data?.success) {
        toggleModal('Edit', response?.data?.result[0]);
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  const handleClick = (id, type) => {
    if (type === 'View') {
      PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1])
        ? navigate(
            `/user_requests_permission/view/${btoa(id)}/${btoa(
              'userRequest',
            )}/${btoa(JSON.stringify(buttonPermission))}`,
          )
        : navigate(
            `/user_requests/view/${btoa(id)}/${btoa('userRequest')}/${btoa(
              JSON.stringify(buttonPermission),
            )}`,
          );
    } else if (type == 'Delete') {
      callDelete(id, 'userRequest');
    } else if (type == 'Comment') {
      handleEditData(id);
    } else if (type == 'approve') {
      handleApprove(id, 'APPROVED');
    } else if (type == 'reject') {
      handleApprove(id, 'REJECTED');
    }
  };

  const toggleModal = (type, value) => {
    if (value && type === 'Edit') {
      modalData = value;
    }
    setModal(!modal);
  };

  const getIsPermission = () => {
    try {
      setIsPermissionComp(
        PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1]) ? true : false,
      );
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };
  const handleSearch = (e) => {
    let value = e.target.value.trim();

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      handleFilterChange({ ...filterValue, search: value });
    }, 400);
  };
  useEffect(() => {
    getLeaveManageList();
    if (data) {
      handleButtonPermission();
    }

    return () => {
      setLoading(false);
      setLeaveList([]);
    };
  }, [data]);

  return (
    <div>
      <div className="row mx-0 mb-2 ">
        <div className="col-md-4 px-0">
          <h5 className="text-nowrap">User Requests</h5>
        </div>
        <div className="col-md-3 px-0 ps-md-5" style={{ marginLeft: 'auto' }}>
          <Search allowClear onChange={handleSearch} placeholder="Search " />
        </div>
      </div>
      {loading && (
        <div>
          <div>
            <TmFilter
              filterArray={filterArray}
              handleFilterChange={handleFilterChange}
            />
          </div>
          <div className="mt-3">
            <LeaveManagementTable
              handleClick={handleClick}
              columns={
                PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1])
                  ? userRequestPermissionHeaders
                  : userRequestsLeaveHeaders
              }
              rows={leaveList}
              tableCustom={{
                columnCtrl: ['action'],
                hideBtn: {
                  edit: false,
                  approve: true,
                  reject: true,
                  delete: buttonPermission?.delete,
                  comment: buttonPermission?.comment,
                },
              }}
            />
          </div>
          <div>
            <ModalComponent
              size="md"
              toggleModal={() => toggleModal('Add', null)}
              modalOpen={modal}
              modalBody={
                <CommentModal
                  modalData={modalData}
                  getLeaveManageList={getLeaveManageList}
                  toggleModal={toggleModal}
                />
              }
            />
          </div>
        </div>
      )}{' '}
      {!loading && <Loader />}
    </div>
  );
};
export default UserRequests;
